import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardGuard } from './sales/guards/auth-guard.guard';

const appRoutes: Routes = [
  { path: '', loadChildren: () => import('./principal/principal.module').then(m => m.PrincipalModule) , canActivate: [AuthGuardGuard] },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },

  // { path: 'auth', loadChildren: './auth/auth.module#AuthModule' },
  // { path: 'sale', loadChildren: './sales/sales.module#SalesModule' },
  // { path: 'incidents', loadChildren: './incidents/incident.module#IncidentModule'},
  // { path: 'stock', loadChildren: './stock/stock.module#StockModule' },
  // { path: 'returns', loadChildren: './returns/returns.module#ReturnsModule' },
  // { path: 'purchases', loadChildren: './purchases/purchases.module#PurchasesModule' },
  // { path: 'sku', loadChildren: './sku/sku.module#SkuModule' },
  // { path: 'prices', loadChildren: './prices/prices.module#PricesModule' },
  // { path: 'configuration', loadChildren: './configuration/configuration.module#ConfigurationModule' },
  // { path: 'mercadolibre', loadChildren: './mercadolibre/mercadolibre.module#MercadolibreModule' },
  // { path: 'payments', loadChildren: './payments/payments.module#PaymentsModule' },
  // { path: 'support-control', loadChildren: './support-control/support-control.module#SupportControlModule' },
	{ path: '**', redirectTo: 'auth/login' }
]

const config: ExtraOptions = {
  useHash: true,
}

@NgModule({
  imports: [
      RouterModule.forRoot(appRoutes,
        // { enableTracing: true }
      )
  ],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule { }
