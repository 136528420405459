import { Component, OnInit } from '@angular/core';
import { SalesServiceService } from '../../sales/services/sales-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthServiceService } from '../../auth/services/auth-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public boolie: boolean;
  message:string;
  menu_data:any = [];
  constructor(private data: SalesServiceService, private toastr: ToastrService, private router: Router,private authenticationService: AuthServiceService) {
    this.boolie = true;
    this.getMenu();
  }

  ngOnInit() {
  }

  getMenu(){
    this.authenticationService.getMenuData()
        .subscribe(
          result => {
            this.menu_data = result;
          },
          error => {
            if (error.error.detail === 'Signature has expired.') {
              this.toastr.warning('Por favor vuelve a iniciar sesion', 'Sesion expirada');
              localStorage.clear();
              this.router.navigate(['/auth/login']);
            }
          });
  }

  expandSidebar(): void {
  	this.boolie = !this.boolie
  	this.data.changeMessage('change');
  	this.data.currentMessage.subscribe(message => this.message = message)
  }
  goOut(): void {
    this.toastr.info("Has cerrado sesión exitosamente", "Hasta pronto");
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  sortBy(prop: string, array:any) {
    return array.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }


}
