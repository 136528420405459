import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Component, OnInit  } from '@angular/core';
@Component({
  selector: 'app-root',
  template: `
        <router-outlet></router-outlet>
        <ngx-loading-bar [includeSpinner]="false" [color]="'black'"></ngx-loading-bar>
      `
})
export class AppComponent implements OnInit{
  constructor(
      titleService:Title,
      router:Router,
      activatedRoute:ActivatedRoute
    ) {
      router.events.subscribe(event => {
        // // console.log(event)
        if(event instanceof NavigationEnd) {
          var title = this.getTitle(router.routerState, router.routerState.root).join('-');
          titleService.setTitle(title);
        }
      });
    }

    ngOnInit() {
    }
    // collect that title data properties from all child routes
    // there might be a better way but this worked for me
    getTitle(state, parent) {
      var data = [];
      if(parent && parent.snapshot.data && parent.snapshot.data.title) {
        data.push(parent.snapshot.data.title);
      }

      if(state && parent) {
        data.push(... this.getTitle(state, state.firstChild(parent)));
      }
      return data;
    }
  }
