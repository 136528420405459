import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
// import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retryWhen, flatMap } from 'rxjs/operators';
import { of, interval } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class XhrErrorHandlerService {
public handleError (error: HttpErrorResponse | any) {
	// console.log("ñlkjhghjk")
    let errMsg: any = {};    
    if (error instanceof HttpErrorResponse && Object.keys(error.error).length < 5 && (error.status !== 0 && error.status !== 401)) {
      errMsg = {
        errMsg: error.error,
        status: error.status
      };
    } else {
      errMsg = {
        errMsg: {
            failure: ['Algo ha salido mal, inténtalo de nuevo']
          },
        status: error.status
      };
    }
    return throwError(errMsg);
  }
}