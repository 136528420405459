import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Http, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { map, catchError, retryWhen, flatMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsiascannService {
	public headers: HttpHeaders = new HttpHeaders();
  token: string;

  constructor(public http: HttpClient,) {
  	if (JSON.parse(localStorage.getItem('currentUser'))){
      this.token = JSON.parse(localStorage.getItem('asiascann')|| '{}').access;
      this.headers = this.headers.append('Authorization', 'Bearer '+ this.token);
      this.headers = this.headers.append('Access-Control-Allow-Origin', "*");
  	}
   }
   auth(){
    return this.http.post(environment.url_asiascann+'auth/login', {'username':'juan.galvez','password':'asiamerica'},{headers:this.headers})
   }

   consultabloqueo(usuario_nombre:string, habilitado_scann:boolean,pregunta:boolean): any {
    return this.http.post(environment.url_asiascann+'scanner/consultabloqueo', {'usuario_nombre':usuario_nombre,'habilitado_scann':habilitado_scann,'pregunta':pregunta},
    {headers:this.headers})
  }

  liberacion(manifiesto:string, usuario_1:string,usuario_1_nombre:string): any {
    return this.http.post(environment.url_asiascann+'scanner/liberacion', {'manifiesto':manifiesto,'usuario_1':usuario_1,'usuario_1_nombre':usuario_1_nombre},
    {headers:this.headers})
  }

}