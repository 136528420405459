import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, interval } from 'rxjs';
import { map, catchError, retryWhen, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { XhrErrorHandlerService } from "../../services/xhr-error-handler.service";
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

interface TokenResponse {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  public token: string;
  public headers: HttpHeaders = new HttpHeaders();
  constructor(
    public http: HttpClient,
    public jwtHelper: JwtHelperService,
    private xhrErrorHandlerService: XhrErrorHandlerService,
  ) {
    if (JSON.parse(localStorage.getItem('currentUser'))){
      this.token = JSON.parse(localStorage.getItem('currentUser')).token;
      this.headers = this.headers.append('Authorization', 'Bearer '+ this.token);
    }

   }

  login(loginParams: any): Observable<boolean>{
        return this.http.post(environment.url+'login/',loginParams)
          .pipe(
            map((response: TokenResponse) => {
                let token = response.token;
                if (token) {
                    this.token = token;
                    localStorage.setItem('currentUser', JSON.stringify({ token: token }));
                    return true;
                } else {
                    return false;
                }
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    }


    forgotRequest(forgotParam: any): any{
        return this.http.post(environment.url+'forgot/password/',forgotParam)
          .pipe(
            map((response: any) => {

                    return response;

            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    }

    changeRequest(changeParam: any): any{
        return this.http.post(environment.url+'change/password/',changeParam)
          .pipe(
            map((response:any) => {
                    return response;

            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    }

    refresh(token): Observable<boolean> {
    return this.http.post(environment.url+'refresh/', { token:token })
    .pipe(
      map((response: any) => {
        const token = response.access;
        localStorage.removeItem('currentUser');
        localStorage.setItem('currentUser', JSON.stringify( {token: token}));
        // var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this.token = currentUser && currentUser.token;
        return true;
      }),
      catchError(this.xhrErrorHandlerService.handleError)
    )
  }

  logout(): void {
        this.token = null;
        localStorage.removeItem('currentUser');
        localStorage.removeItem('state');
        location.href = '/';
    }

    getMenuData(): Observable<any> {
      // console.log("aqui");
      return this.http.get(environment.url + 'menu_app', {headers:this.headers})
         .pipe(
           map((response: any) => {
             return response;

           })
      )
  }



}
