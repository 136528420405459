import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatCardModule
} from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { NgxUploaderModule } from 'ngx-uploader';
import { JwtModule } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { LayoutModule } from './@layout/layout.module'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AgGridModule } from 'ag-grid-angular';
import { TokenInterceptor } from './services/toke.interceptor';
import { XhrErrorHandlerService } from './services/xhr-error-handler.service';
//import {FileSelectDirective} from "ng2-file-upload";
import { AsiascannService } from './services/asiscann.service';
export function tokenGetter() {
  return localStorage.getItem('access_token');
}
const modules = [
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatCardModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        LoadingBarHttpClientModule,
        NgxUploaderModule,
        FormsModule,
        LayoutModule,
        AgGridModule.withComponents(null),
        JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          whitelistedDomains: ['https://api.asiamerica.cl/'],
          blacklistedRoutes: ['https://app.asiamerica.cl/auth/']
        }
      })
];
@NgModule({
  declarations: [
    AppComponent
    //FileSelectDirective
  ],
  imports: [...modules],
  providers: [
    AsiascannService
  // {
  //    provide: HTTP_INTERCEPTORS,
  //    useClass: TokenInterceptor,
  //    multi: true
  //  },
  //  XhrErrorHandlerService
],
  bootstrap: [AppComponent]
})
export class AppModule { }
