// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   url: 'http://localhost:8000/',
//   url_front: 'http://localhost:4200/',
//   url_prices_app: 'http://localhost:4999/',
//   url_open_factura: 'kjhgchlashckasjdghskajds',
//   api_token_open_factura: 'kjhgchlashckasjdghskajds',
//   url_asiascann:'http://localhost:8080/',
//   debug: true,
// };
export const environment = {
  production: true,
  url: 'https://api-amazon.asiamerica.cl/',
  url_front: 'http://app.asiamerica.cl/',
  url_prices_app: 'http://18.207.215.64/',
  url_open_factura: "https://api.haulmer.com",
  api_token_open_factura: "a3f8ebf39b63448f9eb7090c796adaed",
  url_asiascann:'http://162.243.169.144:3000/',
  debug: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
